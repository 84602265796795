<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return 'c676f264-f24c-4c15-ab46-ae8b13278223'
    }
  }
}
</script>
